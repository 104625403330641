.full-height {
  height: 100vh;
}

.drop-shadow {
  padding: 5%;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
}

.hightower-background {
  background-image: url(./images/hightower-background.png);
  background-repeat: repeat;
  background-position-x: center;
}

.hightower-button {
  padding: 10px 50px;
  border: 3px solid #4b4f54;
  border-radius: 0px;
  background: #d68624;
  color: #ffffff;
}

.synergy-background {
  background-image: url(./images/synergy-background.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.synergy-button {
  padding: 10px 50px;
  border: 3px solid #1C78BD;
  border-radius: 0px;
  background: #1C78BD;
  color: #ffffff;
}

.tp-background {
  background-color: #182a48;
}

.tp-button {
  padding: 10px 50px;
  border: 3px solid #337ab7;
  border-radius: 0px;
  background: #337ab7;
  color: #ffffff;
}

.aline-background {
  background-color: #eee;
}

.aline-button {
  padding: 10px 50px;
  border: 3px solid #0731F7;
  border-radius: 0px;
  background: #0731F7;
  color: #ffffff;
}

.falcon-background {
  background-color: #E2E1D3;
}

.falcon-button {
  padding: 10px 50px;
  border: 3px solid #1D4460;
  border-radius: 0px;
  background: #1D4460;
  color: #ffffff;
}

.morgia-background {
  background-image: url(./images/morgia-background.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.morgia-button {
  padding: 10px 50px;
  border: 3px solid #1C78BD;
  border-radius: 0px;
  background: #1C78BD;
  color: #ffffff;
}

.harvey-background {
  background-color: #005983;
}

.harvey-button {
  padding: 10px 50px;
  border: 3px solid #1C78BD;
  border-radius: 0px;
  background: #009D4F;
  color: #ffffff;
}

.tbg-background {
  background-image: url(./images/tbg-background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.tbg-button {
  padding: 10px 50px;
  border: 3px solid #000000;
  border-radius: 0px;
  background: #000000;
  color: #ffffff;
}

.fortress-background {
  background-color: #eee;
}

.fortress-button {
  padding: 10px 50px;
  border: 3px solid #1d1f2c;
  border-radius: 0px;
  background: #002856;
  color: #ffffff;
}

.cognetic-background {
  background-color: #eee;
}

.cognetic-button {
  padding: 10px 50px;
  border: 3px solid #244fa5;
  border-radius: 0px;
  background: #244fa5;
  color: #ffffff;
}

.hma-background {
  background-color: #eee;
}

.hma-button {
  padding: 10px 50px;
  border: 3px solid #105938;
  border-radius: 0px;
  background: #105938;
  color: #ffffff;
}

.somerset-background {
  background-color: #A3B2A8;
}

.somerset-button {
  padding: 10px 50px;
  border: 3px solid #A3B2A8;
  border-radius: 0px;
  background: #A3B2A8;
  color: #ffffff;
}

.lexington-background {
	background-color: #175878;
}

.lexington-button {
	padding: 10px 50px;
	border: 3px solid #175878;
	border-radius: 0px;
	background: #175878;
	color: #ffffff;
}

.irongate-background {
	background-color: #CCBCA3;
}

.irongate-button {
	padding: 10px 50px;
	border: 3px solid #CCBCA3;
	border-radius: 0px;
	background: #CCBCA3;
	color: #ffffff;
}

.dsg-background {
	background-image: url(./images/dsg-background.jpg);
	background-repeat: no-repeat;
	background-size: cover;
}

.dsg-button {
	padding: 10px 50px;
	border: 3px solid #4B4F54;
	border-radius: 0px;
	background: #4B4F54;
	color: #ffffff;
}
