.heading{
    display: flex;
    justify-content: center;
    align-items: center;
    height:700px
}
.maintcss{
    --ht-pri-dk-gray: #4B4F54;
    --ht-pri-orange: #FF6A14;
    --ht-pri-lt-gray: #BFB8AF;
    --ht-sec-blue: #34657F;
    --ht-sec-green: #5D6439;
    --ht-sec-orange: #D78825;
    --ht-sec-red: #9A3324;
    --well-thview-brown: #212529;
    padding: 100px;
    font-family: 'Overpass', sans-serif;
    background-color: #f5f4f4;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'EB Garamond', serif;
}

h1 {
    color: var(--ht-sec-green);
}

h2 {
    color: var(--ht-sec-orange);
}

p {
    color: var(--ht-pri-dk-gray);
}